<template>
  <div class="dialog-container">
    <transition name="el-fade-in" mode="out-in">
      <el-row style="display: flex; align-items: center; justify-content: center; width: 100%" v-if="loading == 'addUpdateScreen' && supplierForm == null" key="screen-loading">
        <lottie-player autoplay loop mode="normal" src="/lottie/packaging-source-loading-lottie.json" style="width: 320px"></lottie-player>
      </el-row>
      <el-row v-else key="content-loading">
        <div class="dialog-title">
          <div class="head-title">{{ supplierForm.ID != null ? "Supplier ID" : "New Supplier" }}<span class="yellow-dot">.</span>{{ supplierForm.ID != null ? "  #" + supplierForm.SupplierNo : "  #" + supplierNumber }}</div>
          <el-button class="btn close" type="danger" @click="close()"> <i class="icon-xmark-solid"></i></el-button>
        </div>
        <div class="form">
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Supplier Name</div>
              <el-input v-model="supplierForm.Title" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Title", validation) }}</span>
            </el-col>
          </el-row>
          <div class="title">Address<span class="yellow-dot">.</span></div>
          <el-row :gutter="24">
            <el-col class="input-container" :span="24">
              <div class="text">Address</div>
              <el-input type="textarea" v-model="supplierForm.Address" :rows="5" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Address", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Country</div>
              <el-select
                v-model="supplierForm.CountryId"
                filterable
                v-if="getAllCountries"
                placeholder="Select"
                @clear="
                  supplierForm.CountryId = null;
                  supplierForm.CityId = null;
                "
                clearable
              >
                <el-option v-for="(item, index) in getAllCountries" :key="item.country_id + index" :label="item.country" :value="item.country_id"> </el-option>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CountryId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">City</div>
              <el-select v-model="supplierForm.CityId" filterable placeholder="Select" @clear="supplierForm.CityId = null" clearable :disabled="supplierForm.CountryId == null" v-if="getAllCountries">
                <template v-if="getAllCountries.some((x) => x.country_id == supplierForm.CountryId)">
                  <el-option v-for="item in getAllCountries.find((x) => x.country_id == supplierForm.CountryId).cities" :key="item.city_id" :label="item.name" :value="item.city_id"> </el-option>
                </template>
              </el-select>
              <span class="error-validation">{{ $errorMessage("CityId", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Post Code</div>
              <el-input class="postcode" v-model="supplierForm.PostalCode" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("PostalCode", validation) }}</span>
            </el-col>
          </el-row>

          <div class="line"></div>
          <div class="title">Contact<span class="yellow-dot">.</span></div>
          <el-row :gutter="24">
            <el-col class="input-container" :sm="8">
              <div class="text">Authorized Person</div>
              <el-input v-model="supplierForm.AuthorizedPerson" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("AuthorizedPerson", validation) }}</span>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col class="input-container email" :sm="8">
              <div class="text">Email</div>
              <el-input v-model="supplierForm.Email" placeholder="..."></el-input>
              <span class="error-validation">{{ $errorMessage("Email", validation) }}</span>
            </el-col>
            <el-col class="input-container" :sm="8">
              <div class="text">Contact Number</div>
              <el-input v-model="supplierForm.ContactNumber" placeholder="07776668899"></el-input>
              <span class="error-validation">{{ $errorMessage("ContactNumber", validation) }}</span>
            </el-col>
          </el-row>
          <div class="line"></div>
          <div class="title">Notes<span class="yellow-dot">.</span></div>
          <el-input type="textarea" :rows="5" placeholder="sample" v-model="supplierForm.Notes"> </el-input>
          <div class="line"></div>
        </div>
        <div class="dialog-footer footerbutton">
          <el-button class="btn" type="danger" @click="close()"> <i class="icon-xmark-solid"></i> Cancel</el-button>
          <el-button class="btn" :type="supplierForm.ID != null ? 'info' : 'primary'" @click="addOrUpdate" :loading="loading == 'saveLoading'" :icon="supplierForm.ID != null ? 'el-icon-edit' : 'el-icon-plus'">{{ supplierForm.ID != null ? "Edit Supplier" : "Add New Supplier" }}</el-button>
        </div>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["id"],
  name: "addOrUpdateSuppliersDialog",
  data() {
    return {
      supplierNumber: null,
      supplierForm: null,
      loading: false,
      timeout: null,
    };
  },

  async created() {
    if (this.id) {
      await this.setForm();
    } else {
      await this.checkedId();
    }
    this.getSupplierNumber();
  },
  methods: {
    async setForm() {
      this.supplierForm = await this.$store.dispatch("getOneSupplier", this.id);
    },
    async getSupplierNumber() {
      var res = await this.$client.post("/Supplier/GenerateSupplierNumber");
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.supplierNumber = res.data.Data;
      }
    },
    close(id) {
      this.$emit("close", id);
    },
    async addOrUpdate() {
      this.$store.commit("setSupplierValidation", []);

      if (this.validation.length > 0) {
        return;
      }
      this.loading = "saveLoading";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        var res = await this.$store.dispatch("addUpdateSupplier", this.supplierForm);
        if (!res.HasError) {
          this.supplierForm = JSON.parse(JSON.stringify(this.getClearCustomerForm));
          await this.getSupplierNumber();
          this.close();
        }
        this.loading = false;
      }, 500);
    },
    async checkedId() {
      this.loading = "addUpdateScreen";
      if (this.timeout != null) clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        if (this.id) {
          await this.setForm();
        } else {
          this.supplierForm = JSON.parse(JSON.stringify(this.getClearCustomerForm));
        }
        this.loading = false;
      }, 500);
    },
  },
  
  computed: {
    validation() {
      return this.$store.getters.getSuppliersValidationErrors;
    },
    getAllCountries() {
      return this.$store.getters.getAllCountries;
    },
    getClearCustomerForm() {
      return this.$store.getters.getSuppliersForm;
    },
  },
};
</script>

<style></style>
